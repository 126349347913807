// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import {
  collection,
  doc,
  onSnapshot,
  orderBy,
  query,
  where
} from "firebase/firestore"
import _, { isEmpty } from "lodash"
import moment from "moment-timezone"
import { AtSign, Calendar, PhoneOutgoing, StopCircle } from "react-feather"
import { Link } from "react-router-dom"
import { db } from "../configs/firebase"
import { mapAppointment } from "./model/appointment"
import { store } from "./store"
// ** Axios Imports
const changesets = require("diff-json")

export const appointmentDetailsSubscription = {
  subAppointment: null,
  subNotifications: null,
  subTransactions: null,
  appointmentId: null,
  loading: true
}

const translate = (type, v) => {
  const dictionary = {
    "Rodzaj płaności": {
      ONLINE: "Online",
      OFFLINE: "Stacjonarnie",
      BANK_TRANSFER: "Przelew bankowy",
      CREDIT_CARD: "Karta kredytowa",
      CASH: "Gotówka",
      comment: "Komentarz terapeuty",
      "N/A": "N/A"
    },

    "Status płatności": {
      COMPLETED_AUTOMATICALLY: "Opłacono",
      COMPLETED_MANUALLY: "Opłacono",
      PENDING: "Oczekuje"
    },

    Status: {
      SCHEDULED: "Zaplanowana",
      COMPLETED: "Zrealizowana",
      CANCELED: "Odwołana",
      "CANCELED-PAID": "Odwołana poza terminem"
    },
    "Powód odwołania": {
      THERAPHIST: "Terapeuta odwołał",
      CUSTOMER: "Klient odwołał",
      HOLIDAYS: "Urlop terapeuty"
    },
    "Powód zmiany terminu": {
      CUSTOMER: "Odwołanie klienta",
      THERAPIST_ILLNESS: "Choroba terapeuty",
      THERAPIST_VACATION: "Urlop terapeuty",
      THERAPIST_CHANGE: "Przelozona przez terapeutę",
      THERAPY_RESIGNATION: "Rezygnacja z terapii",
      PROCESS_TERMINATION: "Zakończenie procesu",
      CUSTOMER_ABSENT: "Klient nie pojawił się na wizycie"
    }
  }

  return dictionary[type] ? dictionary[type][v] : v
}

const updateTimeline = (notifications, transactions, user) => {
  const timelineObjects = []
  if (user && user.role === "ADMIN") {
    transactions.map((doc) => {
      const timelineObject = {
        id: doc.id,
        before: {},
        after: {},
        changedAt: doc.data().changedAt.toDate(),
        type: "APPOINTMENT"
      }

      const changer = {
        id: "",
        display: ""
      }

      if (!isEmpty(doc.data().before)) {
        timelineObject["before"] = {
          Klient:
            doc.data().before.customer?.lastName &&
            doc.data().before.customer?.firstName ? `${doc.data().before.customer?.lastName} ${
                  doc.data().before.customer?.firstName
                }` : "",
          Lokalizacja: doc.data().before.location ? doc.data().before.location.name : "",
          Pokój: doc.data().before.room ? doc.data().before.room.name : "",
          ["Komentarz terapeuty"]: doc.data().before.comment ? doc.data().before.comment : "",
          Status: doc.data().before.appointmentStatus || "",
          ["Powód odwołania"]: doc.data().before.cancelReason ? doc.data().before.cancelReason : "",
          ["Powód zmiany terminu"]: doc.data().before.dateChangeReason ? doc.data().before.dateChangeReason : "",
          Wariant:
            doc.data().before.variant && doc.data().before.variant.variant ? doc.data().before.variant.variant.name : "",
          ["Status płatności"]: doc.data().before.paymentStatus || "",
          ["Rodzaj płaności"]: doc.data().before.paymentType || "",
          ["Miesiąc faktury"]: doc.data().before.invoiceMonth || "",
          ["Data i godzina wizyty"]: moment(
            doc.data().before.dateTime?.toDate()
          ).format("YYYY-MM-DD HH:mm")
        }
      }

      if (!isEmpty(doc.data().after)) {
        timelineObject["after"] = {
          Klient:
            doc.data().after?.customer?.lastName &&
            doc.data().after?.customer?.firstName ? `${doc.data().after?.customer?.lastName} ${
                  doc.data().after?.customer?.firstName
                }` : "",
          Lokalizacja: doc.data().after.location ? doc.data().after.location.name : "",
          ["Komentarz terapeuty"]: doc.data().after.comment ? doc.data().after.comment : "",
          Pokój: doc.data().after.room ? doc.data().after.room.name : "",
          Status: doc.data().after.appointmentStatus || "",
          Wariant:
            doc.data().after.variant && doc.data().after.variant.variant ? doc.data().after.variant.variant.name : "",
          ["Powód odwołania"]: doc.data().after.cancelReason ? doc.data().after.cancelReason : "",
          ["Powód zmiany terminu"]: doc.data().after.dateChangeReason ? doc.data().after.dateChangeReason : "",
          ["Status płatności"]: doc.data().after.paymentStatus || "",
          ["Rodzaj płaności"]: doc.data().after.paymentType || "",
          ["Miesiąc faktury"]: doc.data().after.invoiceMonth || "",
          ["Data i godzina wizyty"]: moment(
            doc.data().after.dateTime?.toDate()
          ).format("YYYY-MM-DD HH:mm")
        }
      }
      if (doc.data().after?.updatedBy && doc.data().after?.updatedBy === "CUSTOMER") {
          changer.isCustomer = true
          changer.id =
          doc.data().after?.customer?.id
        changer.display = `${doc.data().after.customer?.lastName} ${
              doc.data().after.customer?.firstName
            }`
      } else {
        changer.id =
        doc.data().after?.updatedBy?.id || doc.data().after?.createdBy?.id
      changer.display = doc.data().after?.updatedBy?.id ? `${doc.data().after.updatedBy?.lastName} ${
            doc.data().after.updatedBy?.firstName
          }` : `${doc.data().after.createdBy?.lastName} ${
            doc.data().after.createdBy?.firstName
          }`
      }

      const differenceList = changesets.diff(
        timelineObject.before,
        timelineObject.after
      )

      if (differenceList.length > 0) {
        timelineObjects.push({
          title: (
            <div>
              <span>
                Zmiana szczegółów wizyty
                {changer.id && (
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-body ml-1"
                    to={changer.id !== "system" ? `/${changer.isCustomer ? 'customers' : 'users'}/${changer.id}` : "#"}
                  >
                    {" "}
                    - {changer.display}
                  </Link>
                )}
              </span>
            </div>
          ),
          icon: <Calendar size={14} />,
          meta: timelineObject.changedAt ? moment(timelineObject.changedAt).format("YYYY-MM-DD HH:mm") : "Pending",
          dateTime: timelineObject.changedAt,
          customContent: (
            <div className="d-flex align-items-center">
              <ul className="list-unstyled">
                {changesets
                  .diff(timelineObject.before, timelineObject.after)
                  .map((d) => {
                    return (
                      <li className="mb-75">
                        <span className="fw-bolder me-25">{d.key}: </span>
                        {(d.type === "update" || d.type === "delete") && (
                          <span>
                            {translate(d.key, d.oldValue) ||
                              (d.oldValue !== "" && "-")}
                          </span>
                        )}
                        {d.type === "update" && d.oldValue !== "" && (
                          <span> {"->"} </span>
                        )}
                        {d.type !== "delete" && (
                          <span>{translate(d.key, d.value) || "-"}</span>
                        )}
                      </li>
                    )
                  })}
              </ul>
            </div>
          )
        })
      }
    })
  }

  notifications.map((doc) => {
    const timelineObject = {
      id: doc.id,
      ...doc.data(),
      isCompletedAt:
        (doc.data().isCompletedAt && doc.data().isCompletedAt.toDate()) ||
        doc.data().createdAt?.toDate() ||
        "-"
    }
    const changer = {
      id: doc.data().createdBy?.id || "",
      display: doc.data().createdBy ? `${doc.data().createdBy.lastName} ${doc.data().createdBy.firstName}` : "System"
    }

    if (timelineObject.type === "EMAIL_CHANGE_THERAPHIST") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z informacją o zmianie terminu został wysłany do terapeuty.` : "Brak pozwoleń do wysyłania emaili. Email z informacją o zmianie terminu nie został wysłany do terapeuty.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }

    if (timelineObject.type === "EMAIL_CHANGE_CUSTOMER") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z informacją o zmianie terminu został wysłany do klienta.` : "Brak pozwoleń do wysyłania emaili. Email z informacją o zmianie terminu nie został wysłany do klienta.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }

    if (timelineObject.type === "EMAIL_PAYMENT") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z linkiem do płatności został wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z linkiem do płatności nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }

    if (timelineObject.type === "EMAIL_MEETING_INVITE") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z linkiem do wizyty online został wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z linkiem do wizyty online nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "EMAIL_FIRST_APPOINTMENT") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z powitaniem w ośrodku został wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z powitaniem w ośrodku nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "EMAIL_NEW_CUSTOMER") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z informacją do terapeuty o nowym kliencie został wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z informacją do terapeuty o nowym kliencie nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "EMAIL_CANCELLED") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z informacją do terapeuty o odwołaniu wizyty wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z informacją o odwołaniu nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
        if (timelineObject.type === "EMAIL_CANCELLED_NOT_PAID") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: `Email z informacją o automatycznym odwołaniu wizyty został wysłany do klienta.`,
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "EMAIL_PAYMENT_CONFIRMATION_THERAPHIST") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - Email
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `Email z informacją do terapeuty o odnotowaniu płatności przez system Przelewy24 za wizytę został wysłany.` : "Brak pozwoleń do wysyłania emaili. Email z informacją do terapeuty o płanotści nie został wysłany.",
        dateTime: timelineObject.isCompletedAt,
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <AtSign size={14} />
        ),
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "SMS_REMINDER") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - SMS
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `SMS z przypomnieniem o wizycie został wysłany z wiadomością poniżej:` : "Brak pozwoleń do wysyłania sms. SMS z przypomnieniem o wizycie nie został wysłany.",
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <PhoneOutgoing size={14} />
        ),
        dateTime: timelineObject.isCompletedAt,
        customContent: !timelineObject.noPermission ? (
          <div className="d-flex align-items-center">
            <span>{timelineObject.smsContent}</span>
          </div>
        ) : null,
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
    if (timelineObject.type === "SMS_NEW_CUSTOMER") {
      timelineObjects.push({
        title: (
          <div>
            <span>
              Notyfikacja - SMS
              {changer.id && (
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-body ml-1"
                  to={changer.id !== "system" ? `/users/${changer.id}` : "#"}
                >
                  {" "}
                  - {changer.display}
                </Link>
              )}
            </span>
          </div>
        ),
        content: !timelineObject.noPermission ? `SMS z informacją o nowym kliencie wysłany z wiadomością poniżej:` : "Brak pozwoleń do wysyłania emaili. SMS z informacją o nowym kliencie nie został wysłany.",
        icon: timelineObject.noPermission ? (
          <StopCircle size={14} />
        ) : (
          <PhoneOutgoing size={14} />
        ),
        dateTime: timelineObject.isCompletedAt,
        customContent: !timelineObject.noPermission ? (
          <div className="d-flex align-items-center">
            <span>{timelineObject.smsContent}</span>
          </div>
        ) : null,
        meta: timelineObject.isCompletedAt ? moment(timelineObject.isCompletedAt).format("YYYY-MM-DD HH:mm") : "Pending"
      })
    }
  })

  const timelineObjectsSorted = _.orderBy(timelineObjects, "dateTime", "desc")

  return timelineObjectsSorted
}

export const appointmentDetailsSlice = createSlice({
  name: "appointmentDetails",
  initialState: {
    appointment: null,
    notifications: [],
    transactions: [],
    timeline: [],
    updatedAt: Date.now().toString()
  },
  reducers: {
    setAppointment(state, action) {
      state.appointment = action.payload
      store.updatedAt = Date.now().toString()
    },
    setNotifications(state, action) {
      state.notifications = action.payload.notifications
      state.timeline = updateTimeline(
        action.payload.notifications,
        state.transactions,
        action.payload.user
      )
      store.updatedAt = Date.now().toString()
    },
    setTransactions(state, action) {
      state.transactions = action.payload.transactions
      state.timeline = updateTimeline(
        state.notifications,
        action.payload.transactions,
        action.payload.user
      )
      store.updatedAt = Date.now().toString()
    }
  }
})

export const subscribeToAppointmentDetails = (appointmentId, user) => {
  if (appointmentDetailsSubscription.subAppointment) {
    if (
      appointmentId &&
      appointmentDetailsSubscription.appointmentId &&
      appointmentDetailsSubscription.appointmentId !== appointmentId
    ) {
      appointmentDetailsSubscription.subAppointment()
      appointmentDetailsSubscription.subNotifications()
      if (appointmentDetailsSubscription.subTransactions) {
        appointmentDetailsSubscription.subTransactions()
      }
    } else {
      return
    }
  }

  appointmentDetailsSubscription.loading = true

  appointmentDetailsSubscription.appointmentId = appointmentId

  try {
    appointmentDetailsSubscription.subAppointment = onSnapshot(
      doc(db, `appointments/${appointmentId}`),
      (data) => {
        if (data.exists) {
          store.dispatch(
            appointmentDetailsSlice.actions.setAppointment(
              mapAppointment({
                id: data.id,
                ...data.data(),
                dateTime: data.data().dateTime.toDate()
              })
            )
          )
          appointmentDetailsSubscription.loading = false
        }
      }
    )
    if (user?.role === "ADMIN") {
      appointmentDetailsSubscription.subTransactions = onSnapshot(
        query(
          collection(db, "transactions"),
          where("context.params", "==", {
            collectionId: "appointments",
            documentId: appointmentId
          }),
          orderBy("changedAt", "desc")
        ),
        (data) => {
          store.dispatch(
            appointmentDetailsSlice.actions.setTransactions({
              transactions: data.empty ? [] : data.docs,
              user
            })
          )
        }
      )
    }
    appointmentDetailsSubscription.subNotifications = onSnapshot(
      query(
        collection(db, "notifications"),
        where("appointementId", "==", appointmentId)
      ),
      (data) => {
        store.dispatch(
          appointmentDetailsSlice.actions.setNotifications({
            notifications: data.empty ? [] : data.docs,
            user
          })
        )
      }
    )
  } catch (e) {
    appointmentDetailsSubscription.loading = false
    console.error(e)
  }
}

export default appointmentDetailsSlice.reducer
