// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { collection, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore"
import { orderBy } from "lodash"
import { db } from "../configs/firebase"
import { store } from "./store"

// ** Axios Imports

const invoiceData = {
  sub: null,
  userId: null
}

const invoiceDataSaved = {
  sub: null,
  userId: null
}

export const getClosedInvoicesForAppointment = createAsyncThunk(
  "invoices/getClosedInvoicesForAppointment",
  async ({ appointmentId }) => {
    try {
      const qA = doc(db, "appointments", appointmentId)
      const appointment = await getDoc(qA)
      if (!appointment.exists()) {
        return { key: `-`, canEdit: false, month: false, appointmentId }
      } else {
        const userId = appointment.data().theraphist.id
        const month = appointment.data().invoiceMonth
              const q = query(
        collection(db, "employeeInvoices"),
        where("userId", "==", userId),
        where("invoiceMonth", "==", month),
        where("isDeleted", "==", false)
      )
        const querySnapshot = await getDocs(q)
      return { key: `${appointmentId}`, canEdit: querySnapshot.docs.length === 0, month }
      }

    } catch (e) {
      console.error(e)
      return { key: `-`, canEdit: false, month: false, appointmentId }
    }
  }
)

export const getClosedInvoicesForPayments = createAsyncThunk(
  "invoices/getClosedInvoicesForPayments",
  async ({ paymentId, appointmentId }) => {
    try {
      const qA = doc(db, "appointments", appointmentId)
      const appointment = await getDoc(qA)
      if (!appointment.exists()) {
        return { key: `-`, canEdit: false, month: false, appointmentId }
      } else {
        const userId = appointment.data().theraphist.id
        const month = appointment.data().invoiceMonth
              const q = query(
        collection(db, "employeeInvoices"),
        where("userId", "==", userId),
        where("invoiceMonth", "==", month),
        where("isDeleted", "==", false)
      )
        const querySnapshot = await getDocs(q)
      return { key: `${paymentId}`, canEdit: querySnapshot.docs.length === 0, month, appointmentId }
      }

    } catch (e) {
      console.error(e)
      return { key: `-`, canEdit: false, month: false, appointmentId }
    }
  }
)

export const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    updatedAt: Date.now().toString(),
    invoiceData: [],
    invoiceDataSaved: [],
    canEditPayments: {},
    canEditAppointments:{}
  },
  reducers: {
    setMonthInvoiceForUser(state, action) {
      state.invoiceData = action.payload
      store.updatedAt = Date.now().toString()
    },
    setSavedInvoice(state, action) {
      state.invoiceDataSaved = action.payload
      store.updatedAt = Date.now().toString()
    }
  },
    extraReducers: (builder) => {
    builder
      .addCase(getClosedInvoicesForPayments.fulfilled, (state, action) => {
        state.canEditPayments = { ...state.canEditPayments, [action.payload.key]: { canEdit: action.payload.canEdit, month: action.payload.month } }
        store.updatedAt = Date.now().toString()
      })
      .addCase(getClosedInvoicesForAppointment.fulfilled, (state, action) => {
        state.canEditAppointments = { ...state.canEditAppointments, [action.payload.key]: { canEdit: action.payload.canEdit, month: action.payload.month } }
        store.updatedAt = Date.now().toString()
      })
  }
})

export const subscribeToSubmittedInvoice = (userId, month) => {
  if (invoiceDataSaved.sub) {
    invoiceDataSaved.sub()
  }

  invoiceDataSaved.userId = userId
  store.dispatch(invoicesSlice.actions.setSavedInvoice([]))
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (userId) constrainsAppointments.push(where("userId", "==", userId))
  // if (month) constrainsAppointments.push(where("invoiceMonth", "==", month))

  try {
    invoiceDataSaved.sub = onSnapshot(
      query(collection(db, "employeeInvoices"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          appointments.push({
            id: doc.id,
            ...doc.data()
          })
        })
        store.dispatch(invoicesSlice.actions.setSavedInvoice(appointments))
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export const subscribeToMonthInvoiceForUser = (userId, month) => {
  if (invoiceData.sub) {
    if (userId && invoiceData.userId && invoiceData.userId !== userId) {
      invoiceData.sub()
    } else if (month && invoiceData.month && invoiceData.month !== month) {
      invoiceData.sub()
    } else {
      return
    }
  }
  if (!userId) {
    return store.dispatch(invoicesSlice.actions.setMonthInvoiceForUser([]))
  }

  invoiceData.userId = userId
  invoiceData.month = month
  const constrainsAppointments = [where("isDeleted", "==", false)]

  if (userId) constrainsAppointments.push(where("theraphist.id", "==", userId))
  if (month) constrainsAppointments.push(where("invoiceMonth", "==", month))

  try {
    invoiceData.sub = onSnapshot(
      query(collection(db, "appointments"), ...constrainsAppointments),
      (data) => {
        const appointments = []
        data.forEach((doc) => {
          if (doc.data().paymentStatus !== "PENDING") {
            appointments.push({
              id: doc.id,
              ...doc.data(),
              dateTime: doc.data().dateTime.toDate()
            })
          }
        })
        store.dispatch(
          invoicesSlice.actions.setMonthInvoiceForUser(
            orderBy(appointments, "dateTime")
          )
        )
        return appointments
      }
    )
  } catch (e) {
    console.error(e)
  }
}

export default invoicesSlice.reducer
